import _ from 'lodash';
window._ = _;

import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.jQuery = window.$ = require("jquery");
window.DataTable = require("laravel-datatables-vite");
window.select2 = require('select2');

import "./theme";

//import "../demo/demo_configurator";
